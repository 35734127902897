import React, {useState} from 'react';

const UserContext = React.createContext();

const UserContextProvider = ({children}) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("equinozioodvUser")));

  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  )
}

export {UserContextProvider, UserContext}
