import React, {useState} from 'react';

const OverlayContext = React.createContext();

const OverlayContextProvider = ({children}) => {
  const [overlay, setOverlay] = useState(null);
  const [importantOverlay, setImportantOverlay] = useState(null);

  return (
    <OverlayContext.Provider value={{overlay, setOverlay, importantOverlay, setImportantOverlay}}>
      {children}
    </OverlayContext.Provider>
  )
}

export {OverlayContextProvider, OverlayContext}
